<template>
   <v-container fluid>
       <v-row justify="center">
           <v-col></v-col>
           <v-col cols="12" class="text-center">
               <v-icon size="90">mdi-water-pump</v-icon>
               <h2>¿Por cuánto tiempo deja la llave del fregadero de su cocina abierta cada día?</h2>
           </v-col>
           <v-col cols="12" sm="12" md="4" class="pb-0">
               <v-text-field
                    v-model="fregadero"
                    :error-messages="fregaderoErrors"
                    label="Minutos con el fregadero abierto"
                    outlined
                    @input="setFregadero($event)"
                    @blur="$v.fregadero.$touch()"
                    inputmode="numeric"
                ></v-text-field>
           </v-col>
           <v-col cols="12" class="text-center">
               <v-btn
                    class="ma-2 white--text"
                    rounded
                    color="indigo"
                    to="/q14"
                    >
                    <v-icon left>mdi-chevron-left</v-icon> Regresar
                </v-btn>
               <v-btn
                    class="ma-2 white--text"
                    rounded
                    color="indigo"
                    :disabled="$v.$invalid"
                    to="/q16"
                    >
                    Finalizar <v-icon right>mdi-chevron-right</v-icon>
                </v-btn>
           </v-col>
           <v-col cols="12" sm="8" class="text-justify">
                <v-alert
                    outlined
                    color="info"
                    >
                    <div class="title">
                        Consejos:
                    </div>
                    <div>
                        Se necesita mucha agua para producir los alimentos que comemos, la energía que usamos y todas las cosas que compramos. Siga los consejos que indicamos más abajo para ahorrar agua y reducir así su consumo mientras cocina. Allí encontrará muchas maneras de reducir su huella hídrica.
                        <br>
                        <br>
                        No deje la llave abierta innecesariamente mientras está cocinando, ya que estará dejando correr agua limpia (así como energía y dinero) por el desagüe. 
                        <br>
                        <br>
                        Instale llaves de bajo flujo en su fregadero. Las convencionales dejan fluir alrededor de 5 galones por minuto, mientras que las de bajo flujo dejan correr sólo 1.5 galones por minuto.
                        Lave las frutas y verduras en un tazón grande con un cepillo vegetal en lugar de usar su llave y desperdiciar agua.
                        <br>
                        <br>
                        ¡Piense en el futuro! No use agua para descongelar alimentos. Por el contrario, déjelos en la nevera durante la noche.
                        <br>
                        <br>
                        Hierva alimentos con tan poca agua como sea posible para ahorrarla, y al hacerlo también ahorrará gas en su estufa. ¡La pasta, yuca y papas sólo necesitan un poco de agua para sumergirse! Además, con menos agua obtendrá más sabor y más nutrientes en sus verduras.
                    </div>
                </v-alert>
           </v-col>
       </v-row>
   </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
//eslint-disable-next-line
import { required, numeric } from 'vuelidate/lib/validators'

export default {
    
    mixins: [validationMixin],

    validations: {
      fregadero: { required, numeric },
    },

    data() {
        return {
            fregadero: ''
        }
    },

    mounted() {
        this.fregadero = this.$store.state.fregadero
        this.$store.state.progress = 85
        this.goBackIfEmpty()
    },

    computed: {
        fregaderoErrors () {
            const errors = []
            if (!this.$v.fregadero.$dirty) return errors
            !this.$v.fregadero.required && errors.push('Minutos con el fregadero abierto es requerido.')
            !this.$v.fregadero.numeric && errors.push('Debe ser un número.')
            return errors
        },
    },

    methods: {
        setFregadero(value) {
            this.$store.state.fregadero = value
            this.$v.fregadero.$touch()
        },
        goBackIfEmpty(){
           if(!this.$store.state.municipio) {
                this.$router.push('/municipio')
           }else if(!this.$store.state.personas) {
                this.$router.push('/personas')
           }else if(!this.$store.state.cereales) {
                this.$router.push('/q1')
           }else if(!this.$store.state.carnes) {
               this.$router.push('/q2')
           }else if(!this.$store.state.lacteos) {
               this.$router.push('/q3')
           }else if(!this.$store.state.huevos) {
               this.$router.push('/q4')
           }else if(!this.$store.state.comida) {
               this.$router.push('/q5')
           }else if(!this.$store.state.dulces) {
               this.$router.push('/q6')
           }else if(!this.$store.state.verduras) {
               this.$router.push('/q7')
           }else if(!this.$store.state.frutas) {
               this.$router.push('/q8')
           }else if(!this.$store.state.tuberculos) {
               this.$router.push('/q9')
           }else if(!this.$store.state.cafe) {
               this.$router.push('/q10')
           }else if(!this.$store.state.tee) {
               this.$router.push('/q11')
           }else if(!this.$store.state.ducha) {
               this.$router.push('/q12')
           }else if(!this.$store.state.manos) {
               this.$router.push('/q13')
           }else if(!this.$store.state.inodoro) {
               this.$router.push('/q14')
           }
       }
    },
}
</script>